import { gql } from "@apollo/client";

export enum OfferComponentType {
  Unknown = 0,
  Salary,
  Bonus,
  Equity,
}

export enum VestingScheduleType {
  Unknown = 0,
  Monthly,
  Quarterly,
  Annually,
}

export enum EquityType {
  Unknown = 0,
  RestrictedStockUnits,
  EmployeeOptions,
}

export enum CompanyType {
  Unknown = 0,
  Private,
  Public,
}

export enum BonusType {
  Unknown = 0,
  Annual,
  OneTimeBonus,
}

export enum BonusCalculationType {
  Unknown = 0,
  FixedAmount,
  PercentageOfSalary,
}

export enum PrivateEquityShareValueCalculationMode {
  Unknown = 0,
  CurrentValuationAndOutstandingShares = 1,
  PreferredPrice = 2,
}

export const STORAGE_KEY_OFFER = "offer";
export const STORAGE_KEY_PROJECTION = "projectionOptions";

export const SUMMARY_TABLE_FRAGMENT = gql`
  fragment SummaryTableFields on SummaryTable {
    rootRowIds
    allRows {
      localId
      cells {
        ... on CashValueCell {
          cashValue
          bottomLabel {
            text
            color
          }
        }
        ... on SalaryDailyRateCell {
          total
          dailyRate
          workingDaysCount
        }
        ... on SourceTypeLabelCell {
          sourceType
        }
        ... on EmptyCell {
          unused
        }
        ... on YearHeaderCell {
          year
        }
        ... on EquityCell {
          currentTotalValue
          sharesCount
        }
        ... on TextCell {
          text
          bottomText
        }
      }
      childRowIds
      defaultExpanded
    }
  }
`;

export const OFFER_EVALUATION = gql`
  ${SUMMARY_TABLE_FRAGMENT}
  fragment OfferEvaluationFields on OfferEvaluation {
    summaryTable {
      ...SummaryTableFields
    }
    chart {
      xGroups {
        name
        points {
          key
          value
        }
      }
      barDefinitions {
        key
        name
        stackId
        stroke
        fill
      }
    }
    projectionFormJson
  }
`;

export const GET_OFFERS = gql`
  query GetOffers {
    getUserOffers {
      id
      title
      creationTime
      packageSummary
    }
  }
`;

export const GET_OFFER = gql`
  ${OFFER_EVALUATION}
  query GetOffer($offerId: String!) {
    getOffer(offerId: $offerId) {
      id
      title
      formData
      creationTime
      currentEvaluation: evaluate(applyProjections: false) {
        ...OfferEvaluationFields
      }
      futureEvaluation: evaluate(applyProjections: true) {
        ...OfferEvaluationFields
      }
    }
  }
`;

export const GET_OFFER_FOR_EDITING = gql`
  ${OFFER_EVALUATION}
  query GetOffer($offerId: String!) {
    getOffer(offerId: $offerId) {
      id
      title
      formData
      creationTime
      currentEvaluation: evaluate(applyProjections: false) {
        ...OfferEvaluationFields
      }
    }
  }
`;

export const COMPARISON = gql`
  ${SUMMARY_TABLE_FRAGMENT}
  query CompareOffers(
    $offers: [String!]
    $startDate: String!
    $endDate: String!
  ) {
    compareOffers(offers: $offers, startDate: $startDate, endDate: $endDate) {
      ...SummaryTableFields
    }
  }
`;

export const EVALUATION_OFFER_WITHOUT_SAVING = gql`
  ${OFFER_EVALUATION}
  query EvaluateOfferWithoutSaving(
    $formData: String!
    $projectionForm: String!
  ) {
    currentEvaluation: evaluateOfferWithoutSaving(
      formData: $formData
      projectionForm: $projectionForm
      applyProjections: false
    ) {
      ...OfferEvaluationFields
    }
    futureEvaluation: evaluateOfferWithoutSaving(
      formData: $formData
      projectionForm: $projectionForm
      applyProjections: true
    ) {
      ...OfferEvaluationFields
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer(
    $offerId: String!
    $offerForm: String!
    $projectionForm: String!
  ) {
    updateOffer(
      offerId: $offerId
      offerForm: $offerForm
      projectionForm: $projectionForm
    ) {
      id
      formData
      creationTime
    }
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($offerId: String!) {
    deleteOffer(offerId: $offerId)
  }
`;

export const CHECK_TICKER = gql`
  query CheckTicker($ticker: String!) {
    checkTicker(ticker: $ticker)
  }
`;

export function trimUnusedFields(value): any {
  if (Array.isArray(value)) {
    return value.map((v) => trimUnusedFields(v));
  }
  if (Object.prototype.toString.call(value) === "[object Date]") {
    return value;
  }
  if (typeof value === "object") {
    const newProps = Object.keys(value)
      .map((key) => [key, trimUnusedFields(value[key])])
      .filter((pair) => pair[1]);
    if (newProps.length === 0) {
      return null;
    }
    return newProps.reduce((newObj, pair) => {
      newObj[pair[0]] = pair[1];
      return newObj;
    }, {});
  }
  if (typeof value == "boolean") {
    return value;
  }
  if (value !== "" && !isNaN(value)) {
    return Number(value);
  }
  return value;
}

export const EMAIL = "help@moongarlic.com";

export function sourceTypeToString(sourceType): string {
  switch (sourceType) {
    case "SALARY":
      return "Salary";
    case "EQUITY":
      return "Equity";
    case "UNVESTED_EQUITY":
      return "Unvested equity";
    case "VESTED_EQUITY":
      return "Vested equity";
    case "BONUS":
      return "Bonus";
    case "TOTAL":
      return "Total";
  }
}
