import { setCookie } from "cookies-next";
import * as uuid from "uuid";

export const GUEST_USER_ID_COOKIE = "MoonGarlicUserId";

export function updateGuestUserCookie(ctx) {
  let userId = ctx.req.cookies[GUEST_USER_ID_COOKIE];
  if (!userId) {
    userId = `guest-${uuid.v4()}@guest.moongarlic.com`;
  }
  let host = ctx.req.headers["x-forwarded-host"] || ctx.req.headers.host;
  if (host.startsWith("www.")) {
    host = host.substring(4);
  }
  host = host.split(":")[0];
  setCookie(GUEST_USER_ID_COOKIE, userId, {
    req: ctx.req,
    res: ctx.res,
    domain: host,
    httpOnly: true,
    secure: process.env.NEXT_PUBLIC_ENV === "prod",
    maxAge: 24 * 60 * 60,
  });
  return userId;
}
