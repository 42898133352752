import { useMemo } from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import merge from "deepmerge";
import { isEqual } from "lodash";
import { setContext } from "@apollo/client/link/context";
import { getSession } from "next-auth/react";
import { GUEST_USER_ID_COOKIE } from "./guest";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient = null;

export const HOST = `${
    process.env.NEXT_PUBLIC_ENV === "prod" ? "https" : "http"
}://${process.env.NEXT_PUBLIC_API_HOST}`;
export const GRAQPHQL_ENDPOINT = `${HOST}/graphql`;

function createApolloClient(serverSession, serverGuestUserCookie) {
  const httpLink = createHttpLink({
    uri: GRAQPHQL_ENDPOINT,
    credentials: "include",
    headers: serverGuestUserCookie
      ? {
          Cookie: `${GUEST_USER_ID_COOKIE}=${serverGuestUserCookie}`,
        }
      : undefined,
  });

  const authLink = setContext(async (_, { headers }) => {
    const session = serverSession ? serverSession : await getSession();
    const token = session ? session.idToken : "";
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([authLink, httpLink]),
    connectToDevTools: process.env.NEXT_PUBLIC_ENV !== "prod",
    cache: new InMemoryCache({}),
  });
}

export function initializeApollo({
  serverSession,
  serverGuestUserCookie,
  initialState = null,
}) {
  const _apolloClient =
    apolloClient ?? createApolloClient(serverSession, serverGuestUserCookie);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () =>
      initializeApollo({
        serverSession: null,
        serverGuestUserCookie: null,
        initialState: state,
      }),
    [state]
  );
  return store;
}

export function withApollo(Component) {
  const WithApolloImpl = (props) => {
    const apolloClient = useApollo(props);
    return (
      <ApolloProvider client={apolloClient}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
  WithApolloImpl.displayName = "WithApolloImpl";
  return WithApolloImpl;
}
