import * as yup from "yup";
import {
  BonusCalculationType,
  BonusType,
  CHECK_TICKER,
  CompanyType,
  EquityType,
  OfferComponentType,
  PrivateEquityShareValueCalculationMode,
  VestingScheduleType,
} from "../../lib/common";
import { v4 as uuidv4 } from "uuid";

export const SALARY_ENTRY = yup.object().shape({
  id: yup.string().required(),
  base: yup
    .number()
    .typeError("Number required, e.g. 100000")
    .min(1, "Must be at least 1")
    .required("Required"),
  startDate: yup.date().typeError("Start date required").required("Required"),
});

export interface SalaryEntryComponent
  extends yup.InferType<typeof SALARY_ENTRY> {}

export const SALARY_SCHEMA = yup.object().shape({
  entries: yup
    .array()
    .of(SALARY_ENTRY)
    .test(
      "dates-increasing",
      // @ts-ignore
      ({ previousDate }) =>
        `Date must be after previous entry: ${previousDate.toLocaleDateString()}`,
      (value, testContext) => {
        const decreasingDateIndex = value.findIndex((next, index) => {
          if (index == 0) {
            return false;
          }
          return next.startDate <= value[index - 1].startDate;
        });
        if (decreasingDateIndex == -1) {
          return true;
        }
        return testContext.createError({
          path: `${testContext.path}[${decreasingDateIndex}].startDate`,
          params: {
            previousDate: value[decreasingDateIndex - 1].startDate,
          },
        });
      }
    ),
});

export interface SalaryComponent extends yup.InferType<typeof SALARY_SCHEMA> {}

export const BONUS_SCHEMA = yup.object().shape({
  bonusType: yup.number().min(1, "Must be selected").required("Required"),
  oneTime: yup.object().when("bonusType", {
    is: BonusType.OneTimeBonus,
    then: (schema) =>
      schema.shape({
        distributionDate: yup
          .date()
          .typeError("Distribution date required")
          .required("Required"),
      }),
  }),
  calculationMode: yup.number().min(1, "Must be selected").required("Required"),
  fixedAmount: yup.object().when("calculationMode", {
    is: BonusCalculationType.FixedAmount,
    then: (schema) =>
      schema.shape({
        amount: yup
          .number()
          .typeError("Number required, e.g. 10000")
          .min(1, "Must be at least 1")
          .required("Required"),
      }),
  }),
  percentageOfSalary: yup.object().when("calculationMode", {
    is: BonusCalculationType.PercentageOfSalary,
    then: (schema) =>
      schema.shape({
        percentage: yup
          .number()
          .typeError("Percentage required, e.g. 15")
          .min(1, "Must be at least 1")
          .required("Required"),
        salaryComponentId: yup.string().required("Required"),
      }),
  }),
});

export interface BonusComponent extends yup.InferType<typeof BONUS_SCHEMA> {}

export const EQUITY_GRANT_SCHEMA = yup.object().shape({
  startDate: yup
    .date()
    .typeError("Grant start date required")
    .required("Required"),
  grantLength: yup
    .number()
    .typeError("Number required, e.g. 4")
    .min(1, "Must be at least 1")
    .required("Required"),
  vestingScheduleType: yup
    .number()
    .min(1, "Must be selected")
    .required("Required"),
  shares: yup
    .number()
    .typeError("Number required, e.g. 42")
    .min(1, "Must be at least 1")
    .required("Required"),
  cliff: yup.bool().default(false),
  cliffInfo: yup.object().when("cliff", {
    is: true,
    then: (schema) =>
      schema.shape({
        length: yup
          .number()
          .typeError("Number required, e.g. 4")
          .min(1, "Must be at least 1")
          .required("Required"),
      }),
  }),
  equityType: yup.number().min(1, "Must be selected").required("Required"),
  employeeOptions: yup.object().when("equityType", {
    is: EquityType.EmployeeOptions,
    then: (schema) =>
      schema.shape({
        strikePrice: yup
          .number()
          .typeError("Number required, e.g. 27")
          .min(0, "Must be positive")
          .required("Required"),
      }),
  }),
  companyType: yup.number().min(1, "Must be selected").required("Required"),
  publicEquity: yup.object().when("companyType", {
    is: CompanyType.Public,
    then: (schema) =>
      schema.shape({
        tickerSymbol: yup
          .string()
          .required("Required!")
          .test("validTicker", `Ticker not found`, async (value, context) => {
            const apolloClient = context.options.context.apolloClient;
            const response = await apolloClient.query({
              query: CHECK_TICKER,
              variables: {
                ticker: value,
              },
            });
            return response.data.checkTicker;
          }),
      }),
  }),
  privateEquity: yup.object().when("companyType", {
    is: CompanyType.Private,
    then: (schema) =>
      schema.shape({
        sharePriceCalculationMode: yup
          .number()
          .min(1, "Must be selected")
          .required("Required"),
        totalOutstandingShares: yup
          .number()
          .nullable()
          .typeError("Number required, e.g. 63")
          .when("sharePriceCalculationMode", {
            is: PrivateEquityShareValueCalculationMode.CurrentValuationAndOutstandingShares,
            then: (schema) =>
              schema.min(1, "Must be at least 1").required("Required"),
          }),
        lastValuation: yup
          .number()
          .nullable()
          .typeError("Number required, e.g. 4200000")
          .when("sharePriceCalculationMode", {
            is: PrivateEquityShareValueCalculationMode.CurrentValuationAndOutstandingShares,
            then: (schema) =>
              schema.min(1, "Must be at least 1").required("Required"),
          }),
        preferredPrice: yup
          .number()
          .nullable()
          .typeError("Number required, e.g. 100")
          .when("sharePriceCalculationMode", {
            is: PrivateEquityShareValueCalculationMode.PreferredPrice,
            then: (schema) =>
              schema.min(1, "Must be at least 1").required("Required"),
          }),
      }),
  }),
});

export interface EquityGrantComponent
  extends yup.InferType<typeof EQUITY_GRANT_SCHEMA> {}

export const OFFER_COMPONENT_SCHEMA = yup.object().shape({
  id: yup.string().required(),
  componentType: yup.number().required(),
  name: yup.string().optional(),
  salary: yup.object().when("componentType", {
    is: OfferComponentType.Salary,
    then: (schema) => SALARY_SCHEMA,
  }),
  bonus: yup.object().when("componentType", {
    is: OfferComponentType.Bonus,
    then: (schema) => BONUS_SCHEMA,
  }),
  equityGrant: yup.object().when("componentType", {
    is: OfferComponentType.Equity,
    then: (schema) => EQUITY_GRANT_SCHEMA,
  }),
});

export interface OfferComponent
  extends yup.InferType<typeof OFFER_COMPONENT_SCHEMA> {}

export const MAX_OFFER_COMPONENTS = 50;

export const OFFER_SCHEMA = yup.object().shape({
  background: yup.object().shape({
    companyName: yup.string().optional(),
    jobTitle: yup.string().optional(),
  }),
  components: yup.array().of(OFFER_COMPONENT_SCHEMA).max(MAX_OFFER_COMPONENTS),
});

export interface OfferFormSchema extends yup.InferType<typeof OFFER_SCHEMA> {}

export function printDate(d) {
  return `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1)
    .toString()
    .padStart(2, "0")}-${d.getUTCDate().toString().padStart(2, "0")}`;
}

export function parseDateOrEmpty(s) {
  if (!s) {
    return "";
  }
  const d = new Date(s);
  return printDate(d);
}

export function salaryEntryInitialValues(entry: SalaryEntryComponent | null) {
  return {
    id: entry?.id || uuidv4(),
    base: entry?.base || "",
    startDate: parseDateOrEmpty(entry?.startDate),
  };
}

function salaryInitialValues(
  salary: SalaryComponent | null,
  deprecatedStartDate: string | null
) {
  let entries = [];
  if (salary?.entries?.length) {
    entries = salary?.entries?.map((e) =>
      salaryEntryInitialValues(e as SalaryEntryComponent)
    );
  } else {
    entries.push(
      salaryEntryInitialValues({
        id: "initial-salary",
        base: salary?.base as number,
        startDate: parseDateOrEmpty(deprecatedStartDate) as unknown,
      } as SalaryEntryComponent)
    );
  }
  return {
    entries,
  };
}

function bonusInitialValues(bonus: BonusComponent | null) {
  return {
    bonusType: bonus?.bonusType || BonusType.Unknown,
    oneTime: {
      distributionDate: parseDateOrEmpty(bonus?.oneTime?.distributionDate),
    },
    calculationMode: bonus?.calculationMode || BonusCalculationType.Unknown,
    fixedAmount: {
      amount: bonus?.fixedAmount?.amount || "",
    },
    percentageOfSalary: {
      percentage: bonus?.percentageOfSalary?.percentage || "",
      salaryComponentId: bonus?.percentageOfSalary?.salaryComponentId || "",
    },
  };
}

function equityGrantInitialValues(equityGrant: EquityGrantComponent | null) {
  return {
    startDate: parseDateOrEmpty(equityGrant?.startDate),
    grantLength: equityGrant?.grantLength || "",
    vestingScheduleType:
      equityGrant?.vestingScheduleType || VestingScheduleType.Unknown,
    shares: equityGrant?.shares || "",
    cliff: equityGrant?.cliff || false,
    cliffInfo: {
      length: equityGrant?.cliffInfo?.length || "",
    },
    companyType: equityGrant?.companyType || CompanyType.Unknown,
    employeeOptions: {
      strikePrice: equityGrant?.employeeOptions?.strikePrice || "",
    },
    equityType: equityGrant?.equityType || EquityType.Unknown,
    publicEquity: {
      tickerSymbol: equityGrant?.publicEquity?.tickerSymbol || "",
    },
    privateEquity: {
      sharePriceCalculationMode:
        equityGrant?.privateEquity?.sharePriceCalculationMode ||
        PrivateEquityShareValueCalculationMode.PreferredPrice,
      totalOutstandingShares:
        equityGrant?.privateEquity?.totalOutstandingShares || null,
      lastValuation: equityGrant?.privateEquity?.lastValuation || null,
      preferredPrice: equityGrant?.privateEquity?.preferredPrice || null,
    },
  };
}

export function offerComponentInitialValues(
  offerComponent: OfferComponent | null,
  defaultName: string,
  deprecatedStartDate: string
) {
  return {
    id: offerComponent?.id || uuidv4(),
    name: offerComponent?.name || defaultName,
    componentType: offerComponent?.componentType || OfferComponentType.Unknown,
    salary: salaryInitialValues(
      offerComponent?.salary as SalaryComponent,
      deprecatedStartDate
    ),
    bonus: bonusInitialValues(offerComponent?.bonus as BonusComponent),
    equityGrant: equityGrantInitialValues(
      offerComponent?.equityGrant as EquityGrantComponent
    ),
  };
}

export function initialValues(offer: OfferFormSchema) {
  return {
    background: {
      companyName: offer.background?.companyName || "",
      jobTitle: offer.background?.jobTitle || "",
    },
    components:
      offer?.components.map((c) =>
        offerComponentInitialValues(
          c as OfferComponent,
          "",
          offer.background?.startDate
        )
      ) || [],
  };
}
