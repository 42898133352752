import {Navbar} from "react-bootstrap";
import {signIn, signOut, useSession} from "next-auth/react";
import styles from "./header.module.scss";
import {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useRouter} from "next/router";
import {useAnalytics} from "use-analytics";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import {useId, useState} from "react";
import Divider from "@mui/material/Divider";
import {usePopupState} from "material-ui-popup-state/hooks";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import Collapse from "@mui/material/Collapse";
import {useQuery} from "@apollo/client";
import {GET_OFFERS} from "../lib/common";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {CircularProgress} from "@mui/material";

const imageSize = 22;


function Logo(props: { className?: string }) {
  return <>
    <Navbar.Brand href="/" className={props.className}>
      <img
          alt=""
          className={styles.icon}
          src={"/crescent_moon.svg"}
          width={imageSize}
          height={imageSize}
      />
      <img
          alt=""
          className={styles.icon}
          src={"/garlic.svg"}
          width={imageSize}
          height={imageSize}
      />
      moongarlic
    </Navbar.Brand>
  </>;
}

function ProfileButton({}) {
  const {data: session} = useSession();
  const email = session?.user?.email;
  const popupId = useId();
  const popupState = usePopupState({variant: 'popover', popupId,});
  return (<div className={styles.profileButton}>
        <Tooltip title="Open account menu">
          <IconButton
              size="small"
              sx={{ml: 2}}
              {...bindTrigger(popupState)}
          >
            <Avatar sx={{width: 32, height: 32}}
                    src={session.user.image}
                    imgProps={{referrerPolicy: "no-referrer"}}/>
          </IconButton>
        </Tooltip>
        <Menu
            {...bindMenu(popupState)}           >
          <MenuItem disabled={true}>
            {email}
          </MenuItem>
          <Divider/>
          <MenuItem onClick={async () => {
            popupState.close();
            await signOut();
          }}>
            Sign out
          </MenuItem>
        </Menu>
      </div>
  );
}

export function LoginButton({}) {
  const {data: session} = useSession();
  if (session) {
    return <ProfileButton/>
  }

  return (
      <Button onClick={() => signIn("google")} sx={{color: 'white', ml: "auto"}}>
        Sign in
      </Button>
  );
}

function OfferListButton(props: { offers, offersLoading: boolean }) {
  const popupId = useId();
  const popupState = usePopupState({variant: 'popover', popupId,});
  const router = useRouter();
  return (<>
        <Button
            sx={{color: 'white', display: {xs: 'none', sm: 'block'}}}
            {...bindTrigger(popupState)}
        >
          Packages
        </Button>
        <Menu
            {...bindMenu(popupState)}           >
          {props.offersLoading ?
              <MenuItem disabled>Loading...</MenuItem>
              : props.offers.map((offer) => {
                return <MenuItem key={offer.id}
                                 onClick={async () => {
                                   popupState.close();
                                   await router.push(`/offer/${offer.id}`);
                                 }}>
                  {offer.title}
                </MenuItem>;
              })}
          <Divider/>
          <MenuItem onClick={async () => {
            popupState.close();
            await router.push(`/offer/new/edit`);
          }}>
            Add new package
          </MenuItem>
        </Menu>
      </>
  );
}

export function Header(props: {}) {
  const {
    loading: getOffersLoading,
    error: offersError,
    data: getOffers,
    previousData: previousGetOffers,
  } = useQuery(GET_OFFERS, {});
  const offersLoading = getOffersLoading && !previousGetOffers;
  const offers = (getOffers ?? previousGetOffers)?.getUserOffers ?? [];

  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  const toggleDrawer =
      (open: boolean) =>
          (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
              return;
            }
            setMenuOpen(open);
          };

  return (<>
        <AppBar position="static">
          <Container maxWidth={false}>
            <Toolbar disableGutters>
              <IconButton
                  sx={{display: {sm: 'none'}}}
                  size="large"
                  aria-haspopup="true"
                  onClick={toggleDrawer(true)}
                  color="inherit"
              >
                <MenuIcon/>
              </IconButton>

              <Logo/>

              <Button sx={{color: 'white', display: {xs: 'none', sm: 'block'}}} href="/">
                Compare
              </Button>

              <OfferListButton offers={offers} offersLoading={offersLoading}/>

              <LoginButton/>
            </Toolbar>
          </Container>
        </AppBar>
        <Drawer
            anchor="left"
            open={menuOpen}
            onClose={toggleDrawer(false)}
        >
          <Toolbar>
            <IconButton
                sx={{ml: 'auto'}}
                onClick={toggleDrawer(false)}
            >
              <Close/>
            </IconButton>
          </Toolbar>
          <List>
            <ListItemButton href={"/"}>
              <ListItemText primary="Compare packages"/>
            </ListItemButton>
            <ListItemButton onClick={handleClick}>
              <ListItemText primary="Packages"/>
              {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {offersLoading ?
                    <ListItem>
                      <ListItemIcon>
                        <CircularProgress/>
                      </ListItemIcon>
                      Loading...
                    </ListItem> :
                    offers.map((offer) => {
                          return <ListItemButton sx={{pl: 4}} key={offer.id}
                                                 href={`/offer/${offer.id}`}>
                            <ListItemText primary={offer.title}
                                          secondary={offer.packageSummary.join(", ")}/>
                          </ListItemButton>;
                        }
                    )}
                <ListItemButton sx={{pl: 4}} href={"/offer/new/edit"}>
                  <ListItemText primary="Add new package"/>
                </ListItemButton>
              </List>
            </Collapse>
          </List>
        </Drawer>
      </>
  );
}